import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, CircularProgress, Link, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { FilteredProduct } from '@/api/filteredProducts';
import DeviceProductSelection from '@/components/PatientPageV2/components/DeviceSelector/DeviceSelector';
import type { VaporizerProductSelection } from '@/components/PatientPageV2/components/DeviceSelector/DeviceSelector.types';
import { FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION } from '@/constants/featureFlags';
import { useFeatureFlags, useGetFilteredProducts } from '@/hooks';
import type { DeviceProduct } from '@/hooks/prescription/usePrescriptionForm';
import { useAppStore } from '@/state-management';

import { MedicationPrescriptionStatus } from '@montugroup/prescription-contracts';
import { AddAdditionalMedicationButton } from '../AddAdditionalMedicationButton/AddAdditionalMedicationButton';
import { TutorialVideoModal } from '../CreatePrescriptionTutorialModal/TutorialVideoModal';
import { MedicationPadItemEditor } from '../PrescriptionItemEditor/MedicationPadItemEditor';
import { PrescriptionPadItemDetailsModal } from '../PrescriptionPadItemDetailsModal/PrescriptionPadItemDetailsModal';
import type { ProductSelectionFeedbackProps } from '../ProductSelectionFeedback/ProductSelectionFeedback';
import { getProductDisplayName } from './PrescriptionPad.utils';

export interface PrescriptionPadProps {
  doctorId: number;
  isConcessionCardHolder?: boolean;
  productFeedbackProps: ProductSelectionFeedbackProps;
}

/**
 * Component used for creating medications.
 */
export const PrescriptionPad = ({ doctorId, isConcessionCardHolder, productFeedbackProps }: PrescriptionPadProps) => {
  const { flags } = useFeatureFlags();
  const ffV4PrescriptionAssistantCancelPrescribedMedication =
    flags[FF_V4_PRESCRIPTION_ASSISTANT_CANCEL_PRESCRIBED_MEDICATION];
  const {
    prescriptionPad: { medicationPadItems, devicePadItems },
    prescriptionHistory: { itemsToReissue, itemsToCancel }
  } = useAppStore.use.prescriptionAssistant();
  const updateMedicationPadItems = useAppStore.use.updateMedicationPadItems();
  const updateItemsToReissue = useAppStore.use.updatePrescriptionHistoryItemsToReissue();
  const updateItemsToCancel = useAppStore.use.updatePrescriptionHistoryItemsToCancel();
  const updateDevicePadItems = useAppStore.use.updateDevicePadItems();

  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isTutorialVideoOpen, setIsTutorialVideoOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(null);
  const [deviceEntriesList, setDeviceEntriesList] = useState<[string, DeviceProduct | null][]>(() =>
    (devicePadItems || []).map((deviceItem) => {
      const newListId = uuidv4();
      return [newListId, deviceItem];
    })
  );

  const { data: filteredProductsData, isLoading: isFilteredProductsLoading } = useGetFilteredProducts({
    doctorId,
    isConcession: isConcessionCardHolder
  });

  useEffect(() => {
    const deviceProducts: DeviceProduct[] = deviceEntriesList
      .map(([, device]) => device)
      .filter((item) => !!item) as DeviceProduct[];
    updateDevicePadItems(deviceProducts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceEntriesList]);

  // set empty medication selection if none added
  useEffect(() => {
    if (ffV4PrescriptionAssistantCancelPrescribedMedication || Boolean(medicationPadItems?.length)) {
      return;
    }

    updateMedicationPadItems([
      {
        medicationPadItemId: uuidv4()
      }
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicationPadItems, ffV4PrescriptionAssistantCancelPrescribedMedication]);

  const handleDeleteDeviceFromList = useCallback((listIdToDelete: string) => {
    setDeviceEntriesList((prevList) => {
      const newList = new Map(prevList);
      newList.delete(listIdToDelete);
      return Array.from(newList);
    });
  }, []);

  const handleSetDeviceAtListId = useCallback(
    (listIdToUpdate: string, { product, quantity }: VaporizerProductSelection) => {
      const newList = new Map(deviceEntriesList);
      const targetedDevice = newList.get(listIdToUpdate);
      // only update if we need to
      if (targetedDevice?.product_id === product.id) {
        return;
      }

      setDeviceEntriesList(() => {
        newList.set(listIdToUpdate, {
          product_id: product.id,
          name: product.productName,
          quantity,
          vaporizer: true
        });
        return Array.from(newList);
      });
    },
    [deviceEntriesList]
  );

  const handleAddDevice = () => {
    setDeviceEntriesList((prevList) => {
      const newListId = uuidv4();
      return [...prevList, [newListId, null]];
    });
  };

  const handlePreviewIconClick = (productToPreview: FilteredProduct | null) => {
    setDetailsModalOpen(true);
    if (productToPreview?.id) {
      setSelectedProductId(productToPreview.id);
    }
  };

  const renderedDevices = useMemo(
    () =>
      deviceEntriesList.map(([listId, device], index) => {
        return (
          <DeviceProductSelection
            key={listId}
            ariaLabel={`device selection ${index + 1}`}
            doctorId={doctorId}
            initialSelectedDevice={device}
            onSelect={(selected) => handleSetDeviceAtListId(listId, selected)}
            onDelete={() => handleDeleteDeviceFromList(listId)}
            onPreviewIconClick={(item) => handlePreviewIconClick(item)}
          />
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deviceEntriesList, doctorId]
  );

  const handleRemovePrescription = (medicationId: string) => {
    const newPrescriptions = (medicationPadItems || []).filter((item) => item.medicationPadItemId !== medicationId);
    updateMedicationPadItems(newPrescriptions);

    const currentMedicationPadItem = medicationPadItems.find((product) => product.medicationPadItemId === medicationId);

    // remove from reissue list if exists
    if (currentMedicationPadItem) {
      const newItemsToReissue = itemsToReissue.filter((item) => {
        if (currentMedicationPadItem?.reissueStatus?.isOutOfStock) {
          return item.medicationPadItem?.productId !== currentMedicationPadItem.reissueStatus?.productId;
        }

        return item.medicationPadItem?.productId !== currentMedicationPadItem.medicationPadItem?.productId;
      });
      updateItemsToReissue(newItemsToReissue);
    }

    const isInactivePrescription =
      currentMedicationPadItem?.prescriptionStatus === MedicationPrescriptionStatus.Cancelled ||
      currentMedicationPadItem?.prescriptionStatus === MedicationPrescriptionStatus.Expired;

    if (!ffV4PrescriptionAssistantCancelPrescribedMedication) {
      return;
    }

    // if the medication is reissued & then removed, add it to the cancel list
    if (currentMedicationPadItem?.reissueStatus?.isReissued) {
      if (ffV4PrescriptionAssistantCancelPrescribedMedication && isInactivePrescription) {
        return;
      }

      if (!currentMedicationPadItem.medicationPadItem?.productId) {
        return;
      }

      if (currentMedicationPadItem.reissueStatus.isOutOfStock) {
        const { productName, supplierName, description, productId } = currentMedicationPadItem.reissueStatus;
        const currentItemToCancel = {
          productId: Number(productId),
          displayName: getProductDisplayName(productName, description, supplierName)
        };
        const newItemsToCancel = [...itemsToCancel, currentItemToCancel];
        return updateItemsToCancel(newItemsToCancel);
      }

      const { productName, supplierName, description } = currentMedicationPadItem.medicationPadItem;

      const currentItemToCancel = {
        productId: currentMedicationPadItem.medicationPadItem.productId,
        displayName: getProductDisplayName(productName, description, supplierName)
      };
      const newItemsToCancel = [...itemsToCancel, currentItemToCancel];
      updateItemsToCancel(newItemsToCancel);
    }
  };

  const handleAddAdditionalPrescription = () => {
    updateMedicationPadItems([
      {
        medicationPadItemId: uuidv4()
      },
      ...(medicationPadItems || [])
    ]);
  };

  const renderedPrescriptionItems = useMemo(() => {
    return (
      filteredProductsData &&
      (medicationPadItems || []).map((medication) => {
        return (
          <MedicationPadItemEditor
            key={medication.medicationPadItemId}
            medicationItemId={medication.medicationPadItemId}
            filterDataSource={filteredProductsData.productFilters}
            medicationDataSource={filteredProductsData.products}
            onRemoveIconClick={handleRemovePrescription}
            onPreviewIconClick={handlePreviewIconClick}
            productSelectionFeedbackProps={productFeedbackProps}
          />
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicationPadItems, filteredProductsData]);

  if (isFilteredProductsLoading) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="center" height={300}>
        <CircularProgress color="inherit" />
      </Stack>
    );
  }

  return (
    <>
      {/* Prescription Details Modal */}
      {selectedProductId && (
        <PrescriptionPadItemDetailsModal
          productId={selectedProductId}
          isModalOpen={detailsModalOpen}
          isConcessionCardHolder={isConcessionCardHolder}
          onClose={() => setDetailsModalOpen(false)}
        />
      )}
      <TutorialVideoModal open={isTutorialVideoOpen} handleClose={() => setIsTutorialVideoOpen(false)} />
      <Stack spacing={6} py={2}>
        {' '}
        {!ffV4PrescriptionAssistantCancelPrescribedMedication && (
          <Alert severity="info">
            <b>A new tool has been added to prescriptions, replacing the manual medication selection menu.</b>
            <br />
            Need help using the new prescription assistant? Click this{' '}
            <Link
              onClick={() => setIsTutorialVideoOpen(true)}
              sx={{ color: 'warning', textDecoration: 'underline !important', cursor: 'pointer' }}
            >
              Link
            </Link>
          </Alert>
        )}
        {/* enable this button when we have atleast one valid medication */}
        {!ffV4PrescriptionAssistantCancelPrescribedMedication && (
          <AddAdditionalMedicationButton
            onClick={handleAddAdditionalPrescription}
            isDisabled={(medicationPadItems || []).some((item) => !item.medicationPadItem)}
          />
        )}
        {renderedPrescriptionItems}
      </Stack>

      <Stack spacing={4}>
        <Button
          variant="text"
          sx={{
            minWidth: 6,
            textTransform: 'capitalize',
            justifyContent: 'flex-start',
            width: 'fit-content',
            fontWeight: 'bold'
          }}
          color={'warning'}
          onClick={handleAddDevice}
          endIcon={<AddIcon />}
        >
          <Typography fontWeight="unset">Add device</Typography>
        </Button>
        {renderedDevices}
      </Stack>
    </>
  );
};
